import debug from 'debug'

import getMatchMedia from './get-match-media'

const log = debug('common:device/match-media/orientation')

log('`common` is awake')

const QUERY_PORTRAIT = '(orientation: portrait)'

const QUERY_LANDSCAPE = '(orientation: landscape)'

export function queryMatchMediaPortrait () {
  /**
   *  log('queryMatchMediaPortrait')
   */

  const matchMedia = getMatchMedia()

  return matchMedia(QUERY_PORTRAIT)
}

export function queryMatchMediaLandscape () {
  /**
   *  log('queryMatchMediaLandscape')
   */

  const matchMedia = getMatchMedia()

  return matchMedia(QUERY_LANDSCAPE)
}

export function isMatchMediaPortrait () {
  /**
   *  log('isMatchMediaPortrait')
   */

  const {
    matches = false
  } = queryMatchMediaPortrait()

  return matches
}

export function isMatchMediaLandscape () {
  /**
   *  log('isMatchMediaLandscape')
   */

  const {
    matches = false
  } = queryMatchMediaLandscape()

  return matches
}
