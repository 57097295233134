import debug from 'debug'

import getMatchMedia from './get-match-media'

const log = debug('common:device/match-media/breakpoints')

log('`common` is awake')

export const EXTRA_SMALL_MAX = 575.98

export const SMALL_MIN = 576
export const SMALL_MAX = 767.98

export const MEDIUM_MIN = 768
export const MEDIUM_MAX = 991.98

export const LARGE_MIN = 992
export const LARGE_MAX = 1199.98

export const EXTRA_LARGE_MAX = 1200

const QUERY_EXTRA_SMALL = `(max-width: ${EXTRA_SMALL_MAX}px)`

const QUERY_SMALL = `(min-width: ${SMALL_MIN}px) and (max-width: ${SMALL_MAX}px)`

const QUERY_MEDIUM = `(min-width: ${MEDIUM_MIN}px) and (max-width: ${MEDIUM_MAX}px)`

const QUERY_LARGE = `(min-width: ${LARGE_MIN}px) and (max-width: ${LARGE_MAX}px)`

const QUERY_EXTRA_LARGE = `(min-width: ${EXTRA_LARGE_MAX}px)`

export function queryMatchMediaExtraSmall () {
  /**
   *  log('queryMatchMediaExtraSmall')
   */

  const matchMedia = getMatchMedia()

  return matchMedia(QUERY_EXTRA_SMALL)
}

export function queryMatchMediaSmall () {
  /**
   *  log('queryMatchMediaSmall')
   */

  const matchMedia = getMatchMedia()

  return matchMedia(QUERY_SMALL)
}

export function queryMatchMediaMedium () {
  /**
   *  log('queryMatchMediaMedium')
   */

  const matchMedia = getMatchMedia()

  return matchMedia(QUERY_MEDIUM)
}

export function queryMatchMediaLarge () {
  /**
   *  log('queryMatchMediaLarge')
   */

  const matchMedia = getMatchMedia()

  return matchMedia(QUERY_LARGE)
}

export function queryMatchMediaExtraLarge () {
  /**
   *  log('queryMatchMediaExtraLarge')
   */

  const matchMedia = getMatchMedia()

  return matchMedia(QUERY_EXTRA_LARGE)
}

export function isMatchMediaExtraSmall () {
  /**
   *  log('isMatchMediaExtraSmall')
   */

  const {
    matches = false
  } = queryMatchMediaExtraSmall()

  return matches
}

export function isMatchMediaSmall () {
  /**
   *  log('isMatchMediaSmall')
   */

  const {
    matches = false
  } = queryMatchMediaSmall()

  return matches
}

export function isMatchMediaMedium () {
  /**
   *  log('isMatchMediaMedium')
   */

  const {
    matches = false
  } = queryMatchMediaMedium()

  return matches
}

export function isMatchMediaLarge () {
  /**
   *  log('isMatchMediaLarge')
   */

  const {
    matches = false
  } = queryMatchMediaLarge()

  return matches
}

export function isMatchMediaExtraLarge () {
  /**
   *  log('isMatchMediaExtraLarge')
   */

  const {
    matches = false
  } = queryMatchMediaExtraLarge()

  return matches
}
