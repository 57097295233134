import debug from 'debug'

const log = debug('common:device/match-media/get-match-media')

log('`common` is awake')

export default function getMatchMedia () {
  /**
   *  log('getMatchMedia')
   */

  const {
    matchMedia = function matchMedia () {
      log('`matchMedia` is not available')

      return {
        addListener () {
          log('`addListener` is not available')
        }
      }
    }
  } = global

  return matchMedia
}
