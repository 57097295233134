import debug from 'debug'

import {
  isMatchMediaPortrait,
  isMatchMediaLandscape
} from './match-media/orientation'

import {
  isMatchMediaExtraSmall,
  isMatchMediaSmall,
  isMatchMediaMedium,
  isMatchMediaLarge,
  isMatchMediaExtraLarge
} from './match-media/breakpoints'

const log = debug('common:device/match-media')

log('`common` is awake')

export function isSmall () {
  /**
   *  log('isSmall')
   */

  return (
    isMatchMediaExtraSmall() || isMatchMediaSmall() || isMatchMediaMedium()
  )
}

export function isLarge () {
  /**
   *  log('isLarge')
   */

  return (
    isMatchMediaLarge() || isMatchMediaExtraLarge()
  )
}

export function isVertical () {
  /**
   *  log('isVertical')
   */

  return (
    isMatchMediaPortrait()
  )
}

export function isHorizontal () {
  /**
   *  log('isHorizontal')
   */

  return (
    isMatchMediaLandscape()
  )
}
