import debug from 'debug'

const log = debug('common')

log('`common` is awake')

export const truthy = (v = '') => !!(v)

export const dedupe = (a = [], v = '') => a.includes(v) ? a : a.concat(v)

export const normalise = (v = '') => '+'.concat(v.trim().split('+').filter(truthy).sort().reduce(dedupe, []).join('+'))

export const defaultData = {}

const renderData = {
  Configuration: '',
  BackendConfiguration: '',
  Language: 'en-GB',
  Currency: 'GBP',
  CurrencyDigits: 0,
  RegionCode: 'en',
  RegionConfiguration: '+UK',
  Width: 2880,
  Height: 1620,
  Camera: 0
}

const DEFAULT_WIDTH = 1920
const DEFAULT_HEIGHT = 1080
const DEFAULT_FOV = 17

const MODELS = 'models'
const PACK = 'pack'
const PAINTCOLOURS = 'paintcolours'
const SIZE = 'size'

const WEB = 'web'
const PRERENDER = 'prerender'
const WEBGL = 'webgl'
const DEALERSHIP = 'dealership'
const PLUS = 'plus'
const VR = 'vr'
const REVIEW = 'review'

const ENVIRONMENTS = 'environments'
const CAMERAS = 'cameras'

/**
 *  Get `Configuration`
 */
export function getConfiguration () {
  /**
   *  log('getConfiguration')
   */

  const {
    Configuration = ''
  } = renderData

  return Configuration.trim()
}

/**
 *  Set `Configuration`
 */
export function setConfiguration (configuration = '') {
  /**
   *  log('setConfiguration')
   */

  renderData.Configuration = configuration.trim()
}

/**
 *  Has `Configuration`
 */
export function hasConfiguration () {
  /**
   *  log('hasConfiguration')
   */

  return !!(
    getConfiguration()
  )
}

/**
 *  Get `BackendConfiguration`
 */
export function getBackendConfiguration () {
  /**
   *  log('getBackendConfiguration')
   */

  const {
    BackendConfiguration = ''
  } = renderData

  return BackendConfiguration.trim()
}

/**
 *  Set `BackendConfiguration`
 */
export function setBackendConfiguration (backendConfiguration = '') {
  /**
   *  log('setBackendConfiguration')
   */

  renderData.BackendConfiguration = backendConfiguration.trim()
}

/**
 *  Has `BackendConfiguration`
 */
export function hasBackendConfiguration () {
  /**
   *  log('hasBackendConfiguration')
   */

  return !!(
    getBackendConfiguration()
  )
}

/**
 *  Get `RegionCode`
 */
export function getRegionCode () {
  /**
   *  log('getRegionCode')
   */

  const {
    RegionCode = ''
  } = renderData

  return RegionCode
}

/**
 *  Set `RegionCode`
 */
export function setRegionCode (regionCode = '') {
  /**
   *  log('setRegionCode')
   */

  renderData.RegionCode = regionCode
}

/**
 *  Has `RegionCode`
 */
export function hasRegionCode () {
  /**
   *  log('hasRegionCode')
   */

  return !!(
    getRegionCode()
  )
}

/**
 *  Get `RegionConfiguration`
 */
export function getRegionConfiguration () {
  /**
   *  log('getRegionConfiguration')
   */

  const {
    RegionConfiguration = ''
  } = renderData

  return RegionConfiguration
}

/**
 *  Set `RegionConfiguration`
 */
export function setRegionConfiguration (regionConfiguration = '') {
  /**
   *  log('setRegionConfiguration')
   */

  renderData.RegionConfiguration = regionConfiguration
}

/**
 *  Has `RegionConfiguration`
 */
export function hasRegionConfiguration () {
  /**
   *  log('hasRegionConfiguration')
   */

  return !!(
    getRegionConfiguration()
  )
}

/**
 *  Get `Currency`
 */
export function getCurrency () {
  /**
   *  log('getCurrency')
   */

  const {
    Currency = ''
  } = renderData

  return Currency
}

/**
 *  Set `Currency`
 */
export function setCurrency (currency = '') {
  /**
   *  log('setCurrency')
   */

  renderData.Currency = currency
}

/**
 *  Has `Currency`
 */
export function hasCurrency () {
  /**
   *  log('hasCurrency')
   */

  return !!(
    getCurrency()
  )
}

/**
 *  Get `Language`
 */
export function getLanguage () {
  /**
   *  log('getLanguage')
   */

  const {
    Language = ''
  } = renderData

  return Language
}

/**
 *  Set `Language`
 */
export function setLanguage (language = '') {
  /**
   *  log('setLanguage')
   */

  renderData.Language = language
}

/**
 *  Has `Language`
 */
export function hasLanguage () {
  /**
   *  log('hasLanguage')
   */

  return !!(
    getLanguage()
  )
}

/**
 *  Get `CurrencyDigits`
 */
export function getCurrencyDigits () {
  /**
   *  log('getCurrencyDigits')
   */

  const {
    CurrencyDigits = 0
  } = renderData

  return parseInt(CurrencyDigits, 10)
}

/**
 *  Set `CurrencyDigits`
 */
export function setCurrencyDigits (currencyDigits = 0) {
  /**
   *  log('setCurrencyDigits')
   */

  renderData.CurrencyDigits = currencyDigits
}

/**
 *  Has `CurrencyDigits`
 */
export function hasCurrencyDigits () {
  /**
   *  log('hasCurrencyDigits')
   */

  return !isNaN(
    getCurrencyDigits()
  )
}

/**
 *  Get `BasePrice`
 */
export function getBasePrice () {
  /**
   *  log('getBasePrice')
   */

  const {
    BasePrice = 0
  } = renderData

  return parseInt(BasePrice, 10)
}

/**
 *  Has `BasePrice`
 */
export function hasBasePrice () {
  /**
   *  log('hasBasePrice')
   */

  return !isNaN(
    getBasePrice()
  )
}

/**
 *  Set `BasePrice`
 */
export function setBasePrice (basePrice = 0) {
  /**
   *  log('setBasePrice')
   */

  renderData.BasePrice = basePrice
}

/**
 *  Get `OptionsPrice`
 */
export function getOptionsPrice () {
  /**
   *  log('getOptionsPrice')
   */

  const {
    OptionsPrice = 0
  } = renderData

  return parseInt(OptionsPrice, 10)
}

/**
 *  Set `OptionsPrice`
 */
export function setOptionsPrice (optionsPrice = 0) {
  /**
   *  log('setOptionsPrice')
   */

  renderData.OptionsPrice = optionsPrice
}

/**
 *  Has `OptionsPrice`
 */
export function hasOptionsPrice () {
  /**
   *  log('hasOptionsPrice')
   */

  return !isNaN(
    getOptionsPrice()
  )
}

/**
 *  Render `TotalPrice`
 */
export function renderTotalPrice (basePrice = getBasePrice(), optionsPrice = getOptionsPrice()) {
  /**
   *  log('renderTotalPrice')
   */

  return (
    basePrice + optionsPrice
  )
}

/**
 *  Get `Socket`
 */
export function getSocket () {
  /**
   *  log('getSocket')
   */

  const {
    Socket = ''
  } = renderData

  return Socket
}

/**
 *  Set `Socket`
 */
export function setSocket (socket = '') {
  /**
   *  log('setSocket')
   */

  renderData.Socket = socket
}

/**
 *  Has `Socket`
 */
export function hasSocket () {
  /**
   *  log('hasSocket')
   */

  return !!(
    getSocket()
  )
}

/**
 *  Get `Code`
 */
export function getCode () {
  /**
   *  log('getCode')
   */

  return Reflect.get(renderData, 'Code')
}

/**
 *  Set `Code`
 */
export function setCode (code = 0) {
  /**
   *  log('setCode')
   */

  Reflect.set(renderData, 'Code', code)
}

/**
 *  Has `Code`
 */
export function hasCode () {
  /**
   *  log('hasCode')
   */

  return Reflect.has(renderData, 'Code')
}

/**
 *  Get `Width`
 */
export function getWidth () {
  /**
   *  log('getWidth')
   */

  return Reflect.get(renderData, 'Width')
}

/**
 *  Set `Width`
 */
export function setWidth (width = 0) {
  /**
    *  log('setWidth')
    */

  Reflect.set(renderData, 'Width', width)
}

/**
 *  Has `Width`
 */
export function hasWidth () {
  /**
   *  log('hasWidth')
   */

  return Reflect.has(renderData, 'Width')
}

/**
 *  Get `Height`
 */
export function getHeight () {
  /**
   *  log('getHeight')
   */

  return Reflect.get(renderData, 'Height')
}

/**
 *  Set `Height`
 */
export function setHeight (height = 0) {
  /**
   *  log('setHeight')
   */

  Reflect.set(renderData, 'Height', height)
}

/**
 *  Has `Height`
 */
export function hasHeight () {
  /**
   *  log('hasHeight')
   */

  return Reflect.has(renderData, 'Height')
}

/**
 *  Get `Quality`
 */
export function getQuality () {
  /**
   *  log('getQuality')
   */

  return Reflect.get(renderData, 'Quality')
}

/**
 *  Set `Quality`
 */
export function setQuality (quality = 0) {
  /**
   *  log('setQuality')
   */

  Reflect.set(renderData, 'Quality', quality)
}

/**
 *  Has `Quality`
 */
export function hasQuality () {
  /**
   *  log('hasQuality')
   */

  return Reflect.has(renderData, 'Quality')
}

/**
 *  Get `Cameras`
 */
export function getCameras () {
  /**
   *  log('getCameras')
   */

  const {
    Cameras = []
  } = renderData

  return Cameras
}

/**
 *  Set `Cameras`
 */
export function setCameras (cameras = []) {
  /**
   *  log('setCameras')
   */

  renderData.Cameras = cameras
}

/**
 *  Get `Camera`
 */
export function getCamera () {
  /**
   *  log('getCamera')
   */

  const {
    Camera = 0
  } = renderData

  return Camera
}

/**
 *  Set `Camera`
 */
export function setCamera (camera = 0) {
  /**
   *  log('setCamera')
   */

  renderData.Camera = camera
}

/**
 *  Get `WebGLCameras`
 */
export function getWebGLCameras () {
  /**
   *  log('getWebGLCameras')
   */

  const {
    WebGLCameras = []
  } = renderData

  return WebGLCameras
}

/**
 *  Set `WebGLCameras`
 */
export function setWebGLCameras (webGLCameras = []) {
  /**
   *  log('setWebGLCameras')
   */

  renderData.WebGLCameras = webGLCameras
}

/**
 *  Has `WebGLCameras`
 */
export function hasWebGLCameras () {
  /**
   *  log('hasWebGLCameras')
   */

  return !!(
    getWebGLCameras().length
  )
}

/**
 *  Get `WebGLCamera`
 */
export function getWebGLCamera () {
  /**
   *  log('getWebGLCamera')
   */

  return Reflect.get(renderData, 'WebGLCamera')
}

/**
 *  Set `WebGLCamera`
 */
export function setWebGLCamera (webGLCamera) {
  /**
   *  log('setWebGLCamera')
   */

  Reflect.set(renderData, 'WebGLCamera', webGLCamera)
}

/**
 *  Has `WebGLCamera`
 */
export function hasWebGLCamera () {
  /**
   *  log('hasWebGLCamera')
   */

  return Reflect.has(renderData, 'WebGLCamera')
}

/**
 *  Get `ReviewCameras`
 */
export function getReviewCameras () {
  /**
   *  log('getReviewCameras')
   */

  const {
    ReviewCameras = []
  } = renderData

  return ReviewCameras
}

/**
 *  Set `ReviewCameras`
 */
export function setReviewCameras (reviewCameras = []) {
  /**
   *  log('setReviewCameras')
   */

  renderData.ReviewCameras = reviewCameras
}

/**
 *  Has `ReviewCameras`
 */
export function hasReviewCameras () {
  /**
   *  log('hasReviewCameras')
   */

  return !!(
    getReviewCameras().length
  )
}

/**
 *  Get `ReviewCamera`
 */
export function getReviewCamera () {
  /**
   *  log('getReviewCamera')
   */

  return Reflect.get(renderData, 'ReviewCamera')
}

/**
 *  Set `ReviewCamera`
 */
export function setReviewCamera (reviewCamera) {
  /**
   *  log('setReviewCamera')
   */

  Reflect.set(renderData, 'ReviewCamera', reviewCamera)
}

/**
 *  Has `ReviewCamera`
 */
export function hasReviewCamera () {
  /**
   *  log('hasReviewCamera')
   */

  return Reflect.has(renderData, 'ReviewCamera')
}

/**
 *  Get `Prices`
 */
export function getPrices () {
  /**
   *  log('getPrices')
   */

  const {
    Prices = []
  } = renderData

  return Prices
}

/**
 *  Set `Prices`
 */
export function setPrices (prices = []) {
  /**
   *  log('setPrices')
   */

  renderData.Prices = prices
}

/**
 *  Has `Prices`
 */
export function hasPrices () {
  /**
   *  log('hasPrices')
   */

  return !!(
    getPrices().length
  )
}

/**
 *  Get `PriceColumnRules`
 */
export function getPriceColumnRules () {
  /**
   *  log('getPriceColumnRules')
   */

  const {
    PriceColumnRules = []
  } = renderData

  return PriceColumnRules
}

/**
 *  Set `PriceColumnRules`
 */
export function setPriceColumnRules (priceColumnRules = []) {
  /**
   *  log('setPriceColumnRules')
   */

  renderData.PriceColumnRules = priceColumnRules
}

/**
 *  Has `PriceColumnRules`
 */
export function hasPriceColumnRules () {
  /**
   *  log('hasPriceColumnRules')
   */

  return !!(
    getPriceColumnRules().length
  )
}

/**
 *  Get `ButtonObjects`
 */
export function getButtonObjects () {
  /**
   *  log('getButtonObjects')
   */

  const {
    ButtonObjects = []
  } = renderData

  return ButtonObjects
}

/**
 *  Set `ButtonObjects`
 */
export function setButtonObjects (buttonObjects = []) {
  /**
   *  log('setButtonObjects')
   */

  renderData.ButtonObjects = buttonObjects
}

/**
 *  Get `PageMode`
 */
export function getPageMode () {
  /**
   *  log('getPageMode')
   */

  const {
    PageMode = ''
  } = renderData

  return PageMode
}

/**
 *  Set `PageMode`
 */
export function setPageMode (pageMode = '') {
  /**
   *  log('setPageMode')
   */

  renderData.PageMode = pageMode
}

/**
 *  Has `PageMode`
 */
export function hasPageMode () {
  /**
   *  log('hasPageMode')
   */

  return !!(
    getPageMode()
  )
}

/**
 *  Get `Configuration` From
 */
export function getConfigurationFrom (configData = {}) {
  /**
   *  log('getConfigurationFrom')
   */

  const {
    Configuration = ''
  } = configData

  return Configuration.trim()
}

/**
 *  Has `Configuration` In
 */
export function hasConfigurationIn (configData) {
  /**
   *  log('hasConfigurationIn')
   */

  return !!(
    getConfigurationFrom(configData)
  )
}

/**
 *  Get `BackendConfiguration` From
 */
export function getBackendConfigurationFrom (configData = {}) {
  /**
   *  log('getBackendConfigurationFrom')
   */

  const {
    BackendConfiguration = ''
  } = configData

  return BackendConfiguration.trim()
}

/**
 *  Has `BackendConfiguration` In
 */
export function hasBackendConfigurationIn (configData) {
  /**
   *  log('hasBackendConfigurationIn')
   */

  return !!(
    getBackendConfigurationFrom(configData)
  )
}

/**
 *  Get `RegionCode` For
 */
export function getRegionCodeFrom (configData = {}) {
  /**
   *  log('getRegionCodeFrom')
   */

  const {
    RegionCode = ''
  } = configData

  return RegionCode
}

/**
 *  Has `RegionCode` In
 */
export function hasRegionCodeIn (configData) {
  /**
   *  log('hasRegionCodeIn')
   */

  return !!(
    getRegionCodeFrom(configData)
  )
}

/**
 *  Get `RegionConfiguration` For
 */
export function getRegionConfigurationFrom (configData = {}) {
  /**
   *  log('getRegionConfigurationFrom')
   */

  const {
    RegionConfiguration = ''
  } = configData

  return RegionConfiguration
}

/**
 *  Has `RegionConfiguration` In
 */
export function hasRegionConfigurationIn (configData) {
  /**
   *  log('hasRegionConfigurationIn')
   */

  return !!(
    getRegionConfigurationFrom(configData)
  )
}

/**
 *  Get `Currency` From
 */
export function getCurrencyFrom (configData = {}) {
  /**
   *  log('getCurrencyFrom')
   */

  const {
    Currency = ''
  } = configData

  return Currency
}

/**
 *  Has `Currency` In
 */
export function hasCurrencyIn (configData) {
  /**
   *  log('hasCurrencyIn')
   */

  return !!(
    getCurrencyFrom(configData)
  )
}

/**
 *  Get `Language` From
 */
export function getLanguageFrom (configData = {}) {
  /**
   *  log('getLanguageFrom')
   */

  const {
    Language = ''
  } = configData

  return Language
}

/**
 *  Has `Language` In
 */
export function hasLanguageIn (configData) {
  /**
   *  log('hasLanguageIn')
   */

  return !!(
    getLanguageFrom(configData)
  )
}

/**
 *  Get `CurrencyDigits` From
 */
export function getCurrencyDigitsFrom (configData = {}) {
  /**
   *  log('getCurrencyDigitsFrom')
   */

  const {
    CurrencyDigits = 0
  } = configData

  return parseInt(CurrencyDigits, 10) // (a zero-length string parses to `NaN` and zero as a string to `0` - `Number` transforms both to zero)
}

/**
 *  Has `CurrencyDigits` In
 */
export function hasCurrencyDigitsIn (configData) {
  /**
   *  log('hasCurrencyDigitsIn')
   */

  return !isNaN(
    getCurrencyDigitsFrom(configData)
  )
}

/**
 *  Get `Prices` From
 */
export function getPricesFrom (configData = {}) {
  /**
   *  log('getPricesFrom')
   */

  const {
    Prices = []
  } = configData

  return Prices
}

/**
 *  Has `Prices` In
 */
export function hasPricesIn (configData) {
  /**
   *  log('hasPricesIn')
   */

  return !!(
    getPricesFrom(configData).length
  )
}

/**
 *  Get `Socket` From
 */
export function getSocketFrom (configData = {}) {
  /**
   *  log('getSocketFrom')
   */

  const {
    Socket = ''
  } = configData

  return Socket
}

/**
 *  Has `Socket` In
 */
export function hasSocketIn (configData) {
  /**
   *  log('hasSocketIn')
   */

  return !!(
    getSocketFrom(configData)
  )
}

/**
 *  Get `Cameras` From
 */
export function getCamerasFrom (configData = {}) {
  /**
   *  log('getCamerasFrom')
   */

  const {
    Cameras = []
  } = configData

  return Cameras
}

/**
 *  Set `Cameras` To
 */
export function setCamerasTo (configData = {}, cameras = []) {
  /**
   *  log('setCamerasTo')
   */

  configData.Cameras = cameras
}

/**
 *  Has `Cameras` In
 */
export function hasCamerasIn (configData) {
  /**
   *  log('hasCamerasIn')
   */

  return !!(
    getCamerasFrom(configData).length
  )
}

/**
 *  Get `WebGLCameras` From
 */
export function getWebGLCamerasFrom (configData = {}) {
  /**
   *  log('getWebGLCamerasFrom')
   */

  const {
    WebGLCameras = []
  } = configData

  return WebGLCameras
}

/**
 *  Set `WebGLCameras` To
 */
export function setWebGLCamerasTo (configData = {}, webGLCameras = []) {
  /**
   *  log('setWebGLCamerasTo')
   */

  configData.WebGLCameras = webGLCameras
}

/**
 *  Has `WebGLCameras` In
 */
export function hasWebGLCamerasIn (configData) {
  /**
   *  log('hasWebGLCamerasIn')
   */

  return !!(
    getWebGLCamerasFrom(configData).length
  )
}

/**
 *  Get `ReviewCameras` From
 */
export function getReviewCamerasFrom (configData = {}) {
  /**
   *  log('getReviewCamerasFrom')
   */

  const {
    ReviewCameras = []
  } = configData

  return ReviewCameras
}

/**
 *  Set `ReviewCameras` To
 */
export function setReviewCamerasTo (configData = {}, reviewCameras = []) {
  /**
   *  log('setReviewCamerasTo')
   */

  configData.ReviewCameras = reviewCameras
}

/**
 *  Has `ReviewCameras` In
 */
export function hasReviewCamerasIn (configData) {
  /**
   *  log('hasReviewCamerasIn')
   */

  return !!(
    getReviewCamerasFrom(configData).length
  )
}

/**
 *  Get `PageMode` From
 */
export function getPageModeFrom (configData = {}) {
  /**
   *  log('getPageModeFrom')
   */

  const {
    PageMode = ''
  } = configData

  return PageMode
}

/**
 *  Has `PageMode` In
 */
export function hasPageModeIn (configData) {
  /**
   *  log('hasPageModeIn')
   */

  return !!(
    getPageModeFrom(configData)
  )
}

export function isPageModeWeb () {
  /**
   *  log('isPageModeWeb')
   */

  const pageMode = getPageMode()

  return (
    pageMode === WEB
  )
}

export function isPageModePreRender () {
  /**
   *  log('isPageModePreRender')
   */

  const pageMode = getPageMode()

  return (
    pageMode === PRERENDER
  )
}

export function isPageModeWebGL () {
  /**
   *  log('isPageModeWebGL')
   */

  const pageMode = getPageMode()

  return (
    pageMode === WEBGL
  )
}

export function isPageModeDealership () {
  /**
   *  log('isPageModeDealership')
   */

  const pageMode = getPageMode()

  return (
    pageMode === DEALERSHIP
  )
}

export function isPageModePlus () {
  /**
   *  log('isPageModePlus')
   */

  const pageMode = getPageMode()

  return (
    pageMode === PLUS
  )
}

export function isPageModeVR () {
  /**
   *  log('isPageModeVR')
   */

  const pageMode = getPageMode()

  return (
    pageMode === VR
  )
}

export function isPageModeReview () {
  /**
   *  log('isPageModeReview')
   */

  const pageMode = getPageMode()

  return (
    pageMode === REVIEW
  )
}

export {
  DEFAULT_WIDTH,
  DEFAULT_HEIGHT,
  DEFAULT_FOV
}

export {
  MODELS,
  PACK,
  PAINTCOLOURS,
  SIZE
}

export {
  WEB,
  PRERENDER,
  WEBGL,
  DEALERSHIP,
  PLUS,
  VR,
  REVIEW
}

export {
  ENVIRONMENTS,
  CAMERAS
}

export default renderData
